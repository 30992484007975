import invert from 'invert-color';

export const slidesBySize = (): number => {
  const screenWidth = document.documentElement.clientWidth;
  const carrouselWidth = screenWidth - 16 * 6 - 40 * 2;
  const slides = Math.trunc(carrouselWidth / 60) - 1;
  return slides < 1 ? 1 : slides;
};
export const normalizeColor = (c: string): string => {
  const clean = `#${String(c).replace(/(#)+/, '').slice(0, 6)}`;
  return clean;
};
export const contrastColor = (c: string): string => invert(normalizeColor(c), true);

export const randomHexColor = (): string => {
  return (
    '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0').toUpperCase()
  );
};

const now = () => {
  const d = new Date().toISOString();
  return d.replace(/-/gi, '').replace(/:/gi, '').replace('T', '_').slice(0, -5);
};
export const uuidName = () => `Padoru#${Date.now()}`;
export const filename = (pName: string, ext: string) => `${pName}_${now()}.${ext}`;

export const mapToObject = <K, T>(map: Map<K, T>) => Object.fromEntries(map);
export const objToMap = <K, T>(obj: object) =>
  new Map(Object.entries(obj)) as unknown as Map<K, T>;

export const delay = (ms: number) => new Promise(done => setTimeout(done, ms));

export const scrollTo = (
  id: string,
  position: ScrollIntoViewOptions['block'] = 'start',
) => {
  document.getElementById(id.replace('#', ''))?.scrollIntoView?.({ block: position });
};

const CM_NEWS_KEY = 'cm_news_v2'; // cambiar esta key si cambia la noticia
export const checkNews = (set?: boolean) => {
  if (set === undefined) {
    return localStorage.getItem(CM_NEWS_KEY);
  } else {
    localStorage.setItem(CM_NEWS_KEY, String(set));
  }
};
